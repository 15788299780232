import React from 'react';

const BlogProcess = () => {
  return (
    <>
      {/* <!-- Process --> */}
      <section className='relative py-24 dark:bg-jacarta-800'>
        <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
          <img src='/images/gradient_light.jpg' alt='gradient' className='h-full w-full' />
        </picture>
        <div className='container'>
          <h2 className='mb-10 text-center font-display text-3xl text-jacarta-700 dark:text-white'>
            Start Your Blockchain Journey With Bit5
          </h2>
          <div className='grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
            <div className='rounded-2.5xl relative border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#000000] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center' />
              <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
                Bit5 supports ERC 721 and ERC 1155 Standards
              </h3>
              <p className='dark:text-jacarta-300'>
                By supporting both ERC-721 and ERC-1155 standards, Bit5 is able to provide
                comprehensive support for Dynamic NFTs. Additionally, the platform allows users to
                search and filter through various sub-categories within dNFT collections
              </p>
            </div>
            <div className='rounded-2.5xl relative border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#000000] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center' />
              <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
                NFT & Token Launchpad
              </h3>
              <p className='dark:text-jacarta-300'>
                Bit5 offers a robust token launchpad for all your minting needs. Whether it be
                ERC-1155s or ERC-721s, we’re here to support you on your minting journey. Interested
                in launching with Bit5?
                <br />
                <a
                  href='https://docs.google.com/forms/d/e/1FAIpQLSfb0LK0nVypb1i1knzI8E_I_oqNEnb-mHwnxW-pfukvZZXfwg/viewform'
                  target='blank'
                  className='underline'
                >
                  Apply here.
                </a>
              </p>
            </div>
            <div className='rounded-2.5xl relative border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#000000] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center' />
              <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
                Treasury for collections
              </h3>
              <p className='dark:text-jacarta-300'>
                Bit5 aims to give back to the most engaged NFT communities by redistributing a
                portion of the market fees collected from their respective collections. These funds
                will be directed back to each community's treasury, allowing them to use the
                resources as they see fit.
              </p>
            </div>
            <div className='rounded-2.5xl relative border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#000000] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center' />
              <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
                Collections with perks
              </h3>
              <p className='dark:text-jacarta-300'>
                Holders of specific collections can enjoy various discounts and perks across the
                site. These benefits may include reduced fees, bonuses, or exclusive access to
                special features.
              </p>
            </div>
            <div className='rounded-2.5xl relative border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#000000] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center' />
              <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
                Borrowing & Lending
              </h3>
              <p className='dark:text-jacarta-300'>
                You can now borrow capital against your NFTs or lend assets to earn interest. The
                best part? It’s by your terms. Check out the platform{' '}
                <a href='https://lending.bit5.com/' target='blank' className='underline'>
                  here.
                </a>
              </p>
            </div>
            <div className='rounded-2.5xl relative border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#000000] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center' />
              <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
                Customizable Dashboard
              </h3>
              <p className='dark:text-jacarta-300'>
                From “Fear & Greed Index” to “Trending Collections”, create your personal dashboard
                to keep you up-to-date on the crypto markets.
              </p>
            </div>
            {/*<div className="relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#8358ff] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
								className="absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600"
							>
								<path fill="none" d="M0 0h24v24H0z" />
								<path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
							</svg>
							<div className="mb-6 inline-flex items-center justify-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									width="24"
									height="24"
									className="h-12 w-12 fill-accent"
								>
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
								</svg>
							</div>
							<h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
								Connect your Wallet
							</h3>
							<p className="dark:text-jacarta-300">
								Once {"you've"} set up your wallet of choice, connect it to OpenSeaby clicking the
								NFT Marketplacein the top right corner.
							</p>
						</div>*/}
          </div>
        </div>
      </section>
      {/* <!-- end process --> */}
    </>
  );
};

export default BlogProcess;

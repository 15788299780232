/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import HeadLine from '../headLine';
import Image from 'next/image';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import Tippy from '@tippyjs/react';
import { normalizeEther, normalizeNumber } from '../../helpers/digits';
import useGetTopTraders from '../../hooks/useGetTopTraders';
import { shortenAddress } from '../../helpers/address';
import Gravatar from 'react-gravatar';
import Icon, { ChainIcon } from '../Icon';
import { TopCollectionsSkeleton } from '../Skelaton';
import { MD5 } from '../../lib/md5';
import { getCdnUrl } from '../../lib/cdnUrl';
import { Dropdown_container } from '../dropdown/dropdown_container';
import { DropDownItemNetwork } from '../dropdown/DropDownItemNetwork';
import { constants } from '../../helpers/constants';
import getChainName, { getTokenName } from '../../helpers/getChainName';

const Top_traders = () => {
  const [selectedChain, setSelectedChain] = useState(constants?.helpers?.chainId);
  const [data, setData] = useState();
  const topTraders = useGetTopTraders(selectedChain);
  console.log('selectedChain', selectedChain);
  const chain = selectedChain;

  useEffect(() => {
    if (topTraders.data !== undefined) {
      setData(topTraders.data);
    }
  }, [topTraders]);

  const [lastData, setLastData] = useState(null);
  return (
    <div>
      <section className='relative sm:pt-0'>
        <div className='mb-8 flex flex-wrap items-center justify-between'>
          <div className='flex flex-wrap items-center' />

          <Dropdown_container
            name={'Chains'}
            className={'!mb-1.5 !mt-0 ml-1.5 w-fit !min-w-fit self-end'}
          >
            <DropDownItemNetwork
              selectedChainId={selectedChain}
              onChange={(val) => {
                setLastData(null);
                setSelectedChain(val);
              }}
            />
          </Dropdown_container>
        </div>
        <div>
          <div className='grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4'>
            {data?.slice(0, 16).map((item) => {
              const { user, volume, chainId } = item;

              return (
                <div
                  className='rounded-2xl flex border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700'
                  key={user?.walletAddress}
                >
                  <figure className='mr-4 flex w-12 shrink-0 items-center'>
                    <Link href={'/user/' + user?.walletAddress}>
                      <a className='relative block w-full'>
                        {/* <img src={image} alt={title} className="rounded-2lg" /> */}
                        {user?.profilePhoto ? (
                          <ImageFixedAO
                            bottomRounded
                            image={getCdnUrl(user?.profilePhoto)}
                            alt={user?.name}
                          />
                        ) : (
                          <Gravatar
                            email={MD5(user?.walletAddress)}
                            className='rounded-2lg'
                            size={48}
                          />
                        )}
                        {/* <div
                                                    className="dark:border-jacarta-600 bg-jacarta-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                                                    {"id"}
                                                </div>*/}
                        {user?.isVerified && (
                          <div
                            className='absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600'
                            data-tippy-content='Verified Collection'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 24 24'
                              width='24'
                              height='24'
                              className='h-[.875rem] w-[.875rem] fill-white'
                            >
                              <path fill='none' d='M0 0h24v24H0z' />
                              <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' />
                            </svg>
                          </div>
                        )}
                      </a>
                    </Link>
                  </figure>
                  <div className='flex flex-col items-start justify-center'>
                    <Link href={'/user/' + user?.walletAddress}>
                      <a className='block'>
                        <span className='font-display font-semibold text-jacarta-700 hover:text-accent dark:text-white'>
                          {user?.name ? user?.name : shortenAddress(user?.walletAddress)}
                        </span>
                      </a>
                    </Link>
                    {/*<span className="dark:text-jacarta-300 text-sm">{amount} ETH</span>*/}
                    <span className='flex items-center whitespace-nowrap rounded-md border-jacarta-100 py-1  dark:border-jacarta-600'>
                      <ChainIcon
                        name={chain}
                        tooltip={getChainName(chain)}
                        type={'chain'}
                        className={'mr-0.5'}
                      />

                      <span className='text text-sm font-medium tracking-tight'>
                        {/*{volume}<br/>*/}
                        {normalizeNumber(volume)}
                        {/*{normalizeNumber(volume)}*/}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
            {!topTraders.data && <TopCollectionsSkeleton count={8} />}
          </div>
          {/*<div className="mt-10 text-center">
            <Link href="/earnings">
              <a className="text-accent mt-8 block text-center text-sm font-bold tracking-tight">
                Go to stats
              </a>
            </Link>
          </div>*/}
        </div>
      </section>
    </div>
  );
};

export default Top_traders;

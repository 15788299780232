import { Bids, Top_collection } from '../../components/component';
import Meta from '../../components/Meta';
import Feature_collections from '../../components/collectrions/Feature_collections';
import Top_traders from '../../components/collectrions/top_traders';
import HomeRankingTable from '../rankings/homeRankings';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import TabPanel from '../../components/TabPanel';
import BlogProcess from '../../components/blog/blog_process';
import HomeCarousel from '../../components/carousel/homeCarousel';
import { HomeSwap } from '../../components/swap';

export default function Home_1() {
  const [tab, setTab] = useState(0);
  const handleChange = (e, value) => {
    setTab(value);
  };
  return (
    <main>
      <Meta title='Bit5 | Create. Collect. Connect.' />
      <HomeCarousel />
      {/* <Hero_3 /> */}
      {/*<Hero/>*/}
      <Feature_collections />
      <div className='container mb-32'>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label='tabs'
          classes={{
            root: 'justify-center',
            scroller: 'grow-0',
            indicator: 'bg-accent',
            flexContainer: 'flex items-center gap-x-0 sm:gap-x-3 lg:gap-x-4',
          }}
          allowScrollButtonsMobile
          variant='scrollable'
        >
          <Tab
            label='Rankings'
            className='font-display text-[0.7rem] normal-case sm:text-2xs md:text-base'
            classes={{
              textColorPrimary: 'text-jacarta-700 dark:text-white',
              selected: '!text-accent',
            }}
            id={0}
            disableRipple
          />
          <Tab
            label='Trending Collections'
            className='whitespace-nowrap font-display text-[0.7rem] normal-case sm:text-2xs md:text-base'
            classes={{
              textColorPrimary: 'text-jacarta-700 dark:text-white',
              selected: '!text-accent',
            }}
            id={1}
            disableRipple
          />
          <Tab
            label='Top Users'
            className='font-display text-[0.7rem] normal-case sm:text-2xs md:text-base'
            classes={{
              textColorPrimary: 'text-jacarta-700 dark:text-white',
              selected: '!text-accent',
            }}
            id={2}
            disableRipple
          />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <HomeRankingTable />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Top_collection />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Top_traders />
        </TabPanel>
      </div>
      <Bids />
      {/*<Services/>*/}
      <BlogProcess />
      {/*<NewseLatter/>*/}
      <HomeSwap />
    </main>
  );
}

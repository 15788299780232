import { SWAP_WIDGET_URL } from '../../helpers/common';
import { SwapCardList } from './card';

export const HomeSwap = () => {
  return (
    <div className='mr-auto ml-auto w-full max-w-screen-xl p-8'>
      <div className='mb-10 text-center text-3xl font-semibold leading-normal text-jacarta-700 dark:text-white'>
        Swap Like A Pro
      </div>
      <div className='flex flex-col items-center justify-between gap-10 md:flex-row md:items-start'>
        <SwapCardList />

        <div id='SquadSwapWidget' className='h-fit w-full'>
          <iframe
            src={`${SWAP_WIDGET_URL}/swap-widget?inputCurrency=BNB&outputCurrency=0x2d2567DeC25c9795117228aDC7FD58116D2E310C&backgroundColor=%23f7f2f9&inputColor=%23e7e0ec&highlightColor=%23674fa3&textColor=%237A6EAA&feeAddress=0x86a6F14C46b88356A6339181b04E4c525A5eE4AB&darktheme=false&height=800px`}
            title='swap-widget'
            className='mx-auto h-[592px] w-full max-w-[450px] rounded-2lg md:ml-auto md:mr-0'
          />
        </div>
      </div>
    </div>
  );
};

import { getCdnUrl } from '../../lib/cdnUrl';
import Link from 'next/link';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'tippy.js/dist/tippy.css';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import { hero_3_swiper_data } from '../../data/hero_3_swiper_data';
import { useRef } from 'react';
import { useAccount } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { Button } from '../Button';

const HomeCarousel = ({}) => {
  const { address, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const [navigationPrevRef, navigationNextRef] = [useRef(null), useRef(null)];

  return (
    <div className='bg-transparent'>
      <div className='relative mx-auto max-w-6xl px-0'>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          loop={true}
          modules={[Navigation, Autoplay]}
          className={'h-full select-none'}
          style={{
            position: 'unset',
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {hero_3_swiper_data({ walletAddress: address }).map((item) => {
            const {
              image,
              mobileImage,
              id,
              title,
              name,
              titleLink,
              userLink,
              buttonText,
              target,
              connect,
            } = item;
            return (
              <SwiperSlide
                key={id}
                className={'flex h-auto min-h-[400px] w-full flex-wrap sm:px-3'}
              >
                <div className='w-full'>
                  <div className='flex h-full flex-wrap items-center'>
                    <div className='flex h-full w-full flex-wrap items-center justify-center'>
                      <div
                        style={{
                          backgroundImage: `url(${getCdnUrl(image, 'w200')})`,
                        }}
                        className='sm:rounded-2.5xl hidden h-full w-full bg-cover bg-center pt-[20%] sm:flex lg:max-h-[425px]'
                      />
                      <div
                        style={{
                          backgroundImage: `url(${getCdnUrl(mobileImage, 'w200')})`,
                        }}
                        className='sm:rounded-2.5xl flex h-full w-full bg-cover bg-center pt-[20%] sm:hidden lg:max-h-[425px]'
                      />
                    </div>
                  </div>
                </div>
                <div className='absolute bottom-3 left-0 right-0 mx-auto flex h-fit w-[95%] flex-wrap items-center justify-center rounded-[0.8rem] bg-jacarta-100 bg-opacity-75 transition-all duration-200 hover:bg-opacity-100 dark:bg-jacarta-900 dark:bg-opacity-75 dark:hover:bg-opacity-100 sm:right-auto sm:left-6 sm:w-full sm:max-w-sm'>
                  <div className='flex h-fit w-full flex-col p-4'>
                    <div>
                      <div className='flex w-fit flex-row space-x-3'>
                        {titleLink ? (
                          <Link href={titleLink}>
                            <h2 className='!m-0 mb-4 w-full pt-1 font-display font-bold text-jacarta-700 dark:text-white'>
                              {title}
                            </h2>
                          </Link>
                        ) : (
                          <h6 className='text-md !m-0 mb-4 w-full pt-1 font-display font-bold text-jacarta-700 dark:text-white'>
                            {title}
                          </h6>
                        )}
                      </div>
                      <div className='w-full overflow-hidden text-base text-jacarta-700 [display:-webkit-box] [-webkit-box-orient:vertical] dark:text-jacarta-200'>
                        <div className='' dangerouslySetInnerHTML={{ __html: name }} />
                      </div>
                    </div>
                    {!!connect && !isConnected ? (
                      <div className='mt-2 flex w-full flex-wrap items-center justify-end space-y-2 sm:space-y-0'>
                        <Button
                          className={
                            'rounded-xl inline-block w-full bg-accent py-2 px-3 text-center text-base font-semibold text-white transition-all duration-300 hover:bg-accent-dark sm:w-fit'
                          }
                          text={'Connect Wallet'}
                          onClick={openConnectModal}
                        />
                      </div>
                    ) : (
                      userLink && (
                        <div className='mt-2 flex w-full flex-wrap items-center justify-end space-y-2 sm:space-y-0'>
                          <Link href={userLink}>
                            <a
                              className='rounded-xl inline-block w-full bg-accent py-2 px-3 text-center text-base font-semibold text-white transition-all duration-300 hover:bg-accent-dark sm:w-fit'
                              {...(!!target && { target })}
                              {...(target === '_blank' && { rel: 'noopener noreferrer' })}
                            >
                              {buttonText}
                            </a>
                          </Link>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          {/* <div className='relative z-10 w-full'> */}
          <div className='mx-auto w-full max-w-[85rem]'>
            <div className='w-full'>
              <div
                ref={navigationPrevRef}
                className='absolute top-2 right-12 z-10 flex h-fit w-fit cursor-pointer items-center sm:right-20 sm:top-auto sm:bottom-6'
              >
                <IoIosArrowDropleftCircle className='text-3xl text-jacarta-100' />
              </div>
              <div
                ref={navigationNextRef}
                className='absolute top-2 right-2 left-auto z-10 flex h-fit w-fit cursor-pointer items-center sm:right-10 sm:top-auto sm:bottom-6'
              >
                <IoIosArrowDroprightCircle className='text-3xl text-jacarta-100' />
              </div>
            </div>
          </div>
          {/* </div> */}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeCarousel;

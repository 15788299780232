import PropTypes from 'prop-types';
import { SwapIcon1, SwapIcon2, SwapIcon3 } from './icons';
import { useTheme } from 'next-themes';

const swapCardItems = ({ isDark }) => [
  {
    id: 1,
    title: 'Effortless Token Exchange',
    desc: 'Seamlessly swap tokens on our NFT marketplace using our intuitive swap widget.',
    icon: <SwapIcon1 className={'h-fit w-4 sm:w-8'} isDark={isDark} />,
  },
  {
    id: 2,
    title: 'Trade Optimization',
    desc: 'Maximize your trading strategies with our powerful tools, ensuring optimal results.',
    icon: <SwapIcon2 className={'h-fit w-4 sm:w-8'} isDark={isDark} />,
  },
  {
    id: 3,
    title: 'Profit Maximization',
    desc: 'Experience ease in boosting your profits as you dive into seamless token swaps. Start optimizing your NFT journey now!',
    icon: <SwapIcon3 className={'h-fit w-4 sm:w-8'} isDark={isDark} />,
  },
];

export const SwapCard = ({ icon, title, desc }) => {
  return (
    <div className='flex flex-row items-center gap-4 self-stretch rounded-2lg border border-[#E7E8EC] bg-white p-3 dark:border-jacarta-300 dark:bg-jacarta-700 sm:p-[12px_20px] md:gap-6 md:p-[24px_40px]'>
      <div className='flex items-center justify-center rounded-full border border-[#E7E8EC] bg-white p-3 dark:border-jacarta-300 dark:bg-jacarta-700 md:p-5'>
        {icon}
      </div>
      <div className='flex flex-[1_0_0] flex-col items-center justify-between'>
        <div className='flex flex-col justify-center'>
          <h3 className='text-lg font-semibold leading-normal text-jacarta-900 dark:text-white'>
            {title}
          </h3>
          <p className='self-stretch text-base font-normal leading-6 text-jacarta-900 dark:text-white'>
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export const SwapCardList = () => {
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  return (
    <div className='flex flex-col items-start gap-4 self-center md:max-w-[50%] md:gap-[72px]'>
      {swapCardItems({ isDark }).map((item) => (
        <SwapCard key={item.id} {...item} />
      ))}
    </div>
  );
};

SwapCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  desc: PropTypes.string,
};

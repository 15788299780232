import RankingTable from './rankingComponent';

const HomeRankingTable = () => {
  return (
    <>
      <RankingTable limit={5} home />
    </>
  );
};

export default HomeRankingTable;
